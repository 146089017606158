<template>
  <v-app>
    <!-- <pages-core-app-bar /> -->

    <pages-core-view />

    <pages-core-footer />
    <!-- <v-snackbar v-model="snackbar" class="mt-8" :color="snackbarColor" multi-line :timeout="snackbarTimeout" top right>
      {{ snackbarText }}
      <v-btn class="pr-0" color="white" text @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar> -->
    <base-material-snackbar
      v-model="snackbar"
      :type="snackbarColor"
      v-bind="{
        timeout: snackbarTimeout,
        top: true,
        right: true
      }"
    >
      {{ snackbarText }}
    </base-material-snackbar>
  </v-app>
</template>

<script>
import { EventBus } from "../../EventBus"
export default {
  name: "PagesIndex404",
  components: {
    // PagesCoreAppBar: () => import("./components/core/AppBar"),
    PagesCoreFooter: () => import("./components/core/Footer"),
    PagesCoreView: () => import("./components/core/View")
  },
  data: () => ({
    snackbarText: "",
    snackbarColor: "error",
    snackbarTimeout: 5000,
    snackbar: false
  }),
  created() {
    this.$log("created accountToken", this.accountToken)
    this.$vuetify.theme.dark = true
    this.$i18n.locale = this.$store.getters.language
    EventBus.$on("show-alert", salert => {
      // this.$log("alertttttttttttttttttttttt ", salert)
      this.snackbarColor = salert.color ? salert.color : "#2196F3"
      this.snackbarTimeout = salert.timeOut ? salert.timeOut : 5000
      this.snackbarText = salert.message
      this.snackbar = true
    })
  },

  beforeDestroy() {
    this.$vuetify.theme.dark = false
  }
}
</script>
